import UIPopover from "../../components/Popover/Popover";
import moment from "moment";
import { Button } from "../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as More } from "../../assets/icons/More.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ShowToast } from "../../components/Toast";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { EmptyTableView } from "../../components/EmptyTableView";
import useActivities from "./useActivities";
import { useQuery } from "@tanstack/react-query";
import { ActivityForm } from "./components/ActivityForm";
import { frequencies } from "./components/RecommendationFrequency";
import { ActivityDeleteConfirm } from "./components/ActivityDeleteConfirm";
import { MultipleCategoryChipView } from "./components/MultipleCategoryChipView";
import { Tooltip } from "react-tooltip";
import { useSearchParams } from "react-router-dom";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="pl-5 name">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 transition bg-gray-200 rounded-full animate-pulse duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 transition bg-gray-200 animate-pulse duration-50" />
                  <div className="w-4 h-4 transition bg-gray-200 animate-pulse duration-50" />
                </div>
                <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="h-4 transition bg-gray-200 w-36 animate-pulse duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-6 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td>
            <div className="w-8 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function ActivityList() {
  const [params] = useSearchParams();
  const activityId = params.get("id");
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    activities,
    total,
    loadMore,
  } = useActivities({ type: "all" });
  const { isLoading: isCategoryLoading, data: categoryData } = useQuery({
    queryKey: ["categories"],
    queryFn: () => api.getPlaceCategories({}),
  });
  const [removePlaceModal, setRemovePlaceModal] = useState<any>(null);
  const [place, setPlace] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);
  const [showAddActivityModal, setShowAddActivityModal] = useState(false);
  const [editActivity, setEditActivity] = useState<any>(null);

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      updateFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
              ? ""
              : "ASC",
      });
    } else {
      updateFilters({ sort_order: "ASC", sort_by: key });
    }
  };

  const handleRemoveActivityConfirm = async () => {
    try {
      // api call
      const activity = removePlaceModal;
      setDeleting(true);
      const response = await api.deleteActivity(activity.id);
      setDeleting(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(activity.id, true);
          setRemovePlaceModal(null);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      setDeleting(false);
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          activities &&
          total &&
          activities.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, activities, total, isLoading]);

  // useEffect(() => {
  //   if (user) {
  //     const newUser = places?.find(
  //       (category) => category.user_id === user.user_id
  //     );
  //     if (newUser) {
  //       setUser(newUser);
  //     }
  //   }
  // }, [places, user]);

  const clearSearchText = () => {
    updateFilters({ keyword: "" });
  };

  const fetchActivity = async (activityId: string) => {
    const response = await api.getActivity(activityId);
    if (response.data) {
      setShowAddActivityModal(true);
      setEditActivity(response.data);
    }
  };

  useEffect(() => {
    if (activityId) {
      fetchActivity(activityId);
    }
  }, [activityId]);

  const handleMaintenance = () => {
    ShowToast({
      type: "error",
      message: "Add Places is Under Maintenance",
    });
  };
  const categories = (categoryData?.data || []).sort((a, b) =>
    a.category_name.localeCompare(b.category_name)
  );
  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-black text-[32px] font-bold">Experiences</h1>
        <Button
          onClick={() => setShowAddActivityModal(true)}
          // onClick={() => handleMaintenance()}
          className="!rounded-full"
        >
          + Add an Experience
        </Button>
      </div>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex flex-wrap items-center justify-between gap-6 pl-2">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  value={filters?.keyword}
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
                <CloseIcon
                  className={`[&_path]:stroke-black [&_path]:stroke-[1px] cursor-pointer ${!filters?.keyword && "opacity-0"
                    }`}
                  onClick={clearSearchText}
                />
              </div>
            </div>
            {/* <div className="flex items-center gap-3">
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setReportedUserModal(true)}
              >
                Reported users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
              <div className="h-[13px] w-[1px] bg-[#ddd]" />
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setBlockedUserModal(true)}
              >
                Blocked users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
            </div> */}
          </div>
          <div className="mt-5 text-[14px] max-h-[calc(100vh-210px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "27%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "11%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "8%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    onClick={() => handleSort("experience_name")}
                    className="pl-5 rounded-l"
                  >
                    <div className="flex items-center cursor-pointer">
                      Experience name
                      {filters.sort_by === "experience_name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>

                  <td>
                    <div className="flex items-center cursor-pointer">
                      Places
                      {/* {filters.sort_by === "blog_count" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  <td onClick={() => handleSort("category")}>
                    <div className="flex items-center cursor-pointer">
                      Hashtags
                      {filters.sort_by === "category" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                            ? "rotate-[180deg]"
                            : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Added on
                      {/* {filters.sort_by === "blog_count" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Frequency
                      {/* {filters.sort_by === "locality" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null} */}
                    </div>
                  </td>

                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !activities?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No activities found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {activities?.map((aclient, i) => (
                  <tr
                    key={aclient.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => {
                      setShowAddActivityModal(true);
                      setEditActivity(aclient);
                    }}
                  >
                    <td className="pl-5 name">
                      <div className="flex items-center gap-3">
                        <img
                          src={
                            aclient?.activity_images?.[0] ||
                            "/images/places-template.png"
                          }
                          onError={(e) => {
                            e.currentTarget.src = "/images/places-template.png";
                          }}
                          className="object-cover w-[31px] h-[31px] rounded-[6px] border border-black/30"
                          alt="user"
                        />
                        <div className="flex flex-col">
                          <div className="flex items-center gap-1">
                            <h5 className="text-[#202020] text-[13px] max-w-[200px] overflow-hidden whitespace-nowrap text-ellipsis">
                              {aclient.experience_name}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </td>

                    {/* <td className="account-manager">
                      {moment
                        .utc(aclient.date_created)
                        .local()
                        .format("MMM DD,YYYY")}
                    </td> */}
                    <td className="account-executive">
                      <div
                        data-tooltip-id={`experiences-my-tooltip-${aclient.id}`}
                        className="max-w-[150px] overflow-hidden whitespace-nowrap text-ellipsis"
                      >
                        {aclient.places?.[0]?.name}
                      </div>
                      <Tooltip
                        id={`experiences-my-tooltip-${aclient?.id}`}
                        className="!w-[200px] z-[2] !border border-[#D6D6D6] !text-black !rounded-md !p-[11px] !bg-white !shadow-[0px_4px_6px_0px_rgba(0,0,0,0.12)]"
                        noArrow
                      >
                        {aclient.places?.[0]?.name}
                      </Tooltip>
                    </td>
                    <td
                      className="subscription"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {typeof aclient.category === "object" ? (
                        <MultipleCategoryChipView
                          data={aclient.category.map((c) => {
                            const category = categoryData?.data?.find(
                              (i) => i.category_name === c
                            );
                            return {
                              category_name: c,
                              bg_color_code: category?.bg_color_code,
                              text_color_code: category?.text_color_code,
                            };
                          })}
                          itemsToShow={2}
                          fieldName="category_name"
                        />
                      ) : (
                        <div
                          className="bg-[#D5FFFC] rounded px-2 py-1 flex flex-col justify-center h-fit font-medium text-[13px] text-[#0C7FC0] w-fit"
                          style={{
                            backgroundColor:
                              categoryData?.data
                                ?.find(
                                  (i) => i.category_name === aclient.category
                                )
                                ?.bg_color_code?.replace("\n", "") || "#D5FFFC",
                            color:
                              categoryData?.data
                                ?.find(
                                  (i) => i.category_name === aclient.category
                                )
                                ?.text_color_code?.replace("\n", "") ||
                              "#0C7FC0",
                          }}
                        >
                          {aclient.category}
                        </div>
                      )}
                    </td>
                    <td>
                      {aclient?.date_created
                        ? moment
                          .utc(aclient.date_created)
                          .local()
                          .format("MMM DD, YYYY")
                        : "-"}
                    </td>
                    <td>
                      <span>
                        {
                          frequencies.find(
                            (f) => f.value == aclient.recommendation_frequency
                          )?.label
                        }
                      </span>
                    </td>
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 border rounded-lg border-gray-270">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            removeUser={() => {
                              close();
                              setRemovePlaceModal(aclient);
                            }}
                            onEdit={() => {
                              close();
                              setShowAddActivityModal(true);
                              setEditActivity(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      {showAddActivityModal && (
        <ActivityForm
          open={showAddActivityModal}
          onClose={() => {
            setShowAddActivityModal(false);
            setEditActivity(null);
          }}
          activity={editActivity}
          refetch={refetch}
          categories={categories}
        />
      )}
      {!!removePlaceModal && (
        <ActivityDeleteConfirm
          title={`Are you sure you want to remove ‘${removePlaceModal?.experience_name}’?`}
          open={!!removePlaceModal}
          onClose={() => setRemovePlaceModal(null)}
          onConfirm={handleRemoveActivityConfirm}
          loading={deleting}
        />
      )}
      {/* {showAddPlaceModal && (
        <AddPlaceForm
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )} */}
      {/* {showAddPlaceModal && (
        <AddSearchPlace
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )} */}
    </div>
  );
}

const Dropdown = ({ removeUser, onEdit }) => {
  return (
    <div className="z-10 w-[205px] bg-white shadow-[0px_4px_6px_0px_rgba(0,0,0,0.09)] border border-[#C8C8C8] rounded-[6px] mr-28">
      <ul className="p-1.5 text-sm " aria-labelledby="dropdownMenuIconButton">
        <li>
          <Button
            variant="icon"
            className="block !py-2 !text-[15px] !leading-[18px] font-normal text-black !px-2.5 !pr-3 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onEdit}
            scaleAnimated={false}
          >
            Edit Experience
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block !py-2 !text-[15px] !leading-[18px] font-normal !px-2.5 !pr-3 hover:bg-gray-100 !text-[#C00] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            Delete Experience
          </Button>
        </li>
      </ul>
    </div>
  );
};
