import { useSearchParams } from "react-router-dom";
import UIPopover from "../../../components/Popover/Popover";
import moment from "moment";
import { Button } from "../../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as More } from "../../../assets/icons/More.svg";
import { ReactComponent as SearchIcon } from "../../../assets/icons/Search.svg";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/Chevrons.svg";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import { ReactComponent as BanIcon } from "../../../assets/icons/slash.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import useUsers from "./useUsers";
import { UserView } from "../View/View";
import { UserDeleteConfirm } from "../components/UserDeleteConfirm";
import { api } from "../../../services/api";
import { UserBlockConfirm } from "../components/UserBlockConfirm";
import { ReportedUserList } from "./ReportedUsers";
import { BlockedUserList } from "./BlockedUser";
import { LoadAnimatedContainer } from "../../../components/AnimatedContainer";
import { EmptyTableView } from "../../../components/EmptyTableView";
import { ShowToast } from "../../../components/Toast";
import { UserIdWithTooltip } from "../components/UserIdWithTooltip";
import { CrownTooltip } from "../components/CrownTooltip";
import { formatPhoneNumber } from "../../../helpers/formatPhoneNumber";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="pl-5 name">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 transition bg-gray-200 rounded-full animate-pulse duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 transition bg-gray-200 animate-pulse duration-50" />
                  <div className="w-4 h-4 transition bg-gray-200 animate-pulse duration-50" />
                </div>
                <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td className="mrr">
            <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function UserList() {
  const [params] = useSearchParams();
  const plusUser = params.get("plusUser");
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    users,
    total,
    loadMore,
  } = useUsers({ type: "normal", isPlusUser: !!plusUser });
  const [reportedUserModal, setReportedUserModal] = useState<boolean>(false);
  const [blockedUserModal, setBlockedUserModal] = useState<boolean>(false);

  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [user, setUser] = useState<any>(null);

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      updateFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
              ? ""
              : "ASC",
      });
    } else {
      updateFilters({ sort_order: "ASC", sort_by: key });
    }
  };
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleDeactivateUserConfirm = async () => {
    try {
      // api call
      const user = deactivateUser;
      setBlocking(true);
      const response = await api.blockUser({ user_id: user.user_id });
      setBlocking(false);
      if (response.status === 200) {
        if (response?.data?.status) {
          refetch(user.user_id, true);
          setDeactivateUser(null);
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteUser({ user_id: user.user_id });
      setDeleting(false);
      if (response.status === 200) {
        if (response.data?.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          setRemoveUserModal(null);
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          users &&
          total &&
          users.length < total &&
          !isLoading
        ) {
          console.log(
            "ccc",
            entries[0].isIntersecting,
            users,
            total,
            isLoading
          );
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total, isLoading]);

  useEffect(() => {
    if (user) {
      const newUser = users?.find(
        (category) => category.user_id === user.user_id
      );
      if (newUser) {
        setUser(newUser);
      }
    }
  }, [users, user]);

  const clearSearchText = () => {
    updateFilters({ keyword: "" });
  };

  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <div className="mb-4">
        <h1 className="text-black text-[32px] font-bold">Users</h1>
      </div>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex flex-wrap items-center justify-between gap-6 pl-2">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  value={filters?.keyword}
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
                <CloseIcon
                  className={`[&_path]:stroke-black [&_path]:stroke-[1px] cursor-pointer ${!filters?.keyword && "opacity-0"
                    }`}
                  onClick={clearSearchText}
                />
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setReportedUserModal(true)}
              >
                Reported users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
              <div className="h-[13px] w-[1px] bg-[#ddd]" />
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setBlockedUserModal(true)}
              >
                Blocked users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
            </div>
          </div>
          <div className="mt-5 text-[14px] max-h-[calc(100vh-210px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    onClick={() => handleSort("name")}
                    className="pl-5 rounded-l"
                  >
                    <div className="flex items-center cursor-pointer">
                      User Name/ID
                      {filters.sort_by === "name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center">Phone number</div>
                  </td>
                  <td onClick={() => handleSort("joined_on")}>
                    <div className="flex items-center cursor-pointer">
                      Joined on
                      {filters.sort_by === "joined_on" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td onClick={() => handleSort("reveals_sent")}>
                    <div className="flex items-center cursor-pointer">
                      Matches initiated
                      {filters.sort_by === "reveals_sent" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  {/* <td onClick={() => handleSort("reveals_received")}>
                    <div className="flex items-center cursor-pointer">
                      Reveals received
                      {filters.sort_by === "reveals_received" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${
                            filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                          }`}
                        />
                      ) : null}
                    </div>
                  </td> */}
                  <td>
                    <div
                      onClick={() => handleSort("last_activity")}
                      className="flex items-center cursor-pointer"
                    >
                      Last activity
                      {filters.sort_by === "last_activity" ? (
                        <ArrowRight
                          className={`sort-icon ${filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !users?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No users found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {users?.map((aclient, i) => (
                  <tr
                    key={aclient.user_id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setUser(aclient)}
                  >
                    <td className="pl-5 name">
                      <div className="flex items-center gap-3">
                        <img
                          src={aclient.user_image}
                          className="object-cover w-10 h-10 rounded-full"
                          alt="user"
                        />
                        <div className="flex flex-col">
                          <div className="flex items-center gap-1">
                            <h5>{aclient.full_name}</h5>
                            {!!aclient.is_plus_user && <CrownTooltip />}
                          </div>
                          <UserIdWithTooltip userId={aclient.user_id} />
                        </div>
                      </div>
                    </td>
                    <td className="subscription">
                      {/* {!!isInactive && aclient.total_past_subscription} */}
                      {/* {!!isTrial && aclient.total_trial_subscription} */}
                      {/* {!!isActive && aclient.total_active_subscription} */}
                      {formatPhoneNumber(aclient.u_phone_number)}
                    </td>
                    <td className="account-manager">
                      {moment
                        .utc(aclient.date_created)
                        .local()
                        .format("MMM DD, YYYY")}
                    </td>
                    <td className="account-executive">
                      <span>{aclient.matches_initiated}</span>
                    </td>
                    {/* <td className="mrr">
                      <span>{aclient.received_count}</span>
                    </td> */}
                    <td>
                      {aclient?.last_activity_date
                        ? moment
                          .utc(aclient.last_activity_date)
                          .local()
                          .format("MMM DD,YYYY | hh:mm A")
                        : "-"}
                    </td>
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 border rounded-lg border-gray-270">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            clientStatus={aclient.status}
                            clientId={aclient.user_id}
                            removeUser={() => {
                              close();
                              setRemoveUserModal(aclient);
                            }}
                            onDeactiveUser={() => {
                              close();
                              setDeactivateUser(aclient);
                            }}
                            onActivateUser={() => console.log("mark as active")}
                            onView={() => {
                              close();
                              setUser(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      <UserView
        open={!!user}
        onClose={() => setUser(null)}
        user={user}
        refetch={refetch}
      />
      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.full_name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <UserBlockConfirm
        title={`Are you sure you want to block ‘${deactivateUser?.full_name}’?`}
        open={!!deactivateUser}
        onClose={() => setDeactivateUser(null)}
        onConfirm={handleDeactivateUserConfirm}
        loading={blocking}
      />
      {reportedUserModal && (
        <ReportedUserList
          open={reportedUserModal}
          onClose={() => setReportedUserModal(false)}
        />
      )}
      {blockedUserModal && (
        <BlockedUserList
          open={blockedUserModal}
          onClose={() => setBlockedUserModal(false)}
          refresh={refetch}
        />
      )}
    </div>
  );
}

const Dropdown = ({
  clientId,
  onActivateUser,
  onDeactiveUser,
  removeUser,
  clientStatus,
  onView,
}) => {
  return (
    <div className="z-10 w-40 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onView}
            scaleAnimated={false}
          >
            <Eye />
            View profile
          </Button>
        </li>
        {clientStatus !== "inactive" ? (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-gray-100 w-full text-left !justify-start"
              onClick={onDeactiveUser}
              scaleAnimated={false}
            >
              <BanIcon />
              Block user
            </Button>
          </li>
        ) : (
          <li>
            <Button
              variant="icon"
              className="block py-2 !px-4 hover:bg-gray-100 w-full text-left !justify-start"
              onClick={onActivateUser}
              scaleAnimated={false}
            >
              <BanIcon />
              Unblock user
            </Button>
          </li>
        )}
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Remove user
          </Button>
        </li>
      </ul>
    </div>
  );
};
