import { Outlet } from "react-router-dom";
import { Sidebar } from "../components/Sidebar";
import { useState } from "react";
import { getUser } from "../services/utils";
// import { UserProvider, useUser } from "./profile/context/user-context";

function MainApp() {
  //   const {userInfo} = useUser() as any;
  const userInfo = getUser();

  return (
    <div className="flex flex-1 bg-background">
      <Sidebar user={userInfo} />
      {/* {collapsed && (
        <MobileSidebar
          user={userInfo}
          onClose={() => setCollapsed((prev) => !prev)}
        />
      )} */}
      <div className="flex-1 max-h-screen bg-background">
        <div className="flex-1 bg-background max-h-screen overflow-auto max-w-[1400px] mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export function Main() {
  // const token = localStorage.getItem("token");
  // const userData = JSON.parse(localStorage.getItem("user") || "{}");
  // if (!token || !userData?.admin_id) {
  //   window.location.href = "/login";
  //   return null;
  // }


  return <MainApp />;
}

{
  /* <UserProvider>
<MainApp />
</UserProvider> */
}
