import moment from "moment";
import { ReactComponent as CrownIcon } from "../../../../assets/icons/crown.svg";
import { formatPhoneNumber } from "react-phone-number-input";
import { CrownTooltip } from "../../components/CrownTooltip";

export function UserInfoCard({ user }) {
  return (
    <div className="flex justify-between items-center bg-white rounded-[16px] p-6">
      <div className="flex items-center gap-[14px]">
        <div className="w-[92px] h-[92px] rounded-full">
          <img
            src={user.user_image}
            alt=""
            className="rounded-full w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex gap-2 items-center ">
            <span className="text-lg text-[#33363F] font-bold">
              {user.full_name}
            </span>
            {user.is_plus_user && <CrownTooltip className="w-5 h-5" />}
          </div>
          <div className="flex items-center text-sm gap-2">
            <span className="text-[#686868]">User ID:</span>
            <span className="text-[#202020]">{user.user_id}</span>
          </div>
          <div className="flex items-center text-sm gap-2">
            <span className="text-[#686868]">Age:</span>
            <span className="text-[#202020]">{user.user_age}</span>
          </div>
          <div className="flex items-center text-sm gap-2">
            <span className="text-[#686868]">Phone#:</span>
            <span className="text-[#202020]">
              {formatPhoneNumber(`${user.country_code}${user.u_phone_number}`)}
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <span className="text-[#686868]">Joined on:</span>
        <span className="text-[#202020]">
          {moment.utc(user.date_created).local().format("MMM DD,YYYY")}
        </span>
      </div>
    </div>
  );
}
