import { useEffect, useState } from "react";
import { Modal } from "../../../components/Modals/Modal";
import { ReactComponent as ChevronIcon } from "../../../assets/icons/Chevrons.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";

export function ImageViewModal({
  open,
  onClose,
  images,
  index,
}: {
  open: boolean;
  onClose: () => void;
  images: string[];
  index: number;
}) {
  const [selectedIndex, setSelectedIndex] = useState(index);

  const handlePrevious = () => {
    setSelectedIndex(selectedIndex - 1);
  };

  const handleNext = () => {
    setSelectedIndex(selectedIndex + 1);
  };

  useEffect(() => {
    setSelectedIndex(index);
  }, [index]);

  return (
    <Modal
      title={""}
      open={open}
      onClose={onClose}
      contentClassName="[&_h1]:text-xl [&_.close]:w-5 [&_.close]:h-5 !max-w-[600px] [&>.header]:mb-2"
    >
      <button
        onClick={onClose}
        aria-label="Close"
        className="absolute top-2 -right-6"
      >
        <CloseIcon className="w-8 h-8 close [&_path]:stroke-white" />
      </button>
      <div className="flex items-center justify-center relative">
        <div className="absolute top-2 py-[3px] px-2 right-2 bg-black/50 rounded-[9px] text-white text-xs tracking-[-0.36px] poppins">
          {selectedIndex + 1} / {images.length}
        </div>
        {selectedIndex > 0 && (
          <button
            style={{
              backdropFilter: "blur(24px)",
              WebkitBackdropFilter: "blur(24px)",
            }}
            className="bg-white/50 p-2 rounded-full absolute left-2"
            onClick={handlePrevious}
          >
            <ChevronIcon className="rotate-180 w-6 h-6" />
          </button>
        )}
        <img
          src={images[selectedIndex]}
          alt="activity"
          className="w-[550px] h-[80vh] rounded-md"
        />
        {images.length > selectedIndex + 1 && (
          <button
            style={{
              backdropFilter: "blur(24px)",
              WebkitBackdropFilter: "blur(24px)",
            }}
            onClick={handleNext}
            className="bg-white/50 p-2 rounded-full absolute right-2"
          >
            <ChevronIcon className="w-6 h-6" />
          </button>
        )}
      </div>
    </Modal>
  );
}
