import { useEffect, useState } from "react";
import { readFile } from "./CropImage";
import ImageCropModalContent from "./ImageCropModalContent";
import { useImageCropContext } from "./provider/ImageCropProvider";
import { Modal } from "../Modals/Modal";
import { api } from "../../services/api";

export const ImageCrop = ({
  image,
  isOpen,
  onClose,
  onImageChange,
}: {
  image: File;
  isOpen: boolean;
  onClose: () => void;
  onImageChange: (data: any) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getProcessedImage, setImage, resetStates } =
    useImageCropContext() as any;

  const handleDone = async () => {
    try {
      const avatar = await getProcessedImage();
      onImageChange({
        url: window.URL.createObjectURL(avatar),
        file: new File([avatar], image.name, {
          type: image.type,
        }),
      });
      resetStates();
      setImage("");
      onClose();
    } catch (error) {}
  };

  useEffect(() => {
    if (isOpen && image) {
      if (typeof image === "string") {
        (async function () {
          setIsLoading(true);
          let blob = await api.downloadImage(`${image}`);
          let dataUrl = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          // now do something with `dataUrl`
          setImage(dataUrl);
          setIsLoading(false);
        })();
      } else {
        readFile(image).then((imageDataUrl) => {
          setImage(imageDataUrl);
        });
      }
    }
  }, [isOpen, image]);

  const handleClose = () => {
    resetStates();
    setImage("");
    onClose();
  };

  return (
    <Modal
      contentClassName="w-full md:mx-auto md:w-[1000px] !max-w-[1000px]"
      onClose={handleClose}
      open={isOpen}
    >
      {isOpen && (
        <ImageCropModalContent
          handleDone={handleDone}
          handleClose={handleClose}
          isLoading={isLoading}
        />
      )}
    </Modal>
  );
};
