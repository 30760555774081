import { useEffect, useMemo, useState } from "react";
import { DatePicker } from "../../../components/DatePicker/DatePicker";
import { SelectableChip } from "./SelectableChip";
import { TimePicker } from "../../../components/DatePicker/TimePicker";
import moment from "moment";

const weekDays = [
  { value: "Breakfast (before 11AM)", label: "Breakfast (before 11AM)" },
  { value: "Lunch (11-2PM)", label: "Lunch (11-2PM) " },
  { value: "Afternoon (2-5PM)", label: "Afternoon (2-5PM)" },
  { value: "Before Dinner (5-7PM)", label: "Before Dinner (5-7PM)" },
  { value: "Dinner (6-8:30PM)", label: "Dinner (6-8:30PM)" },
  {
    value: "After Dinner (8:30-10:30PM)",
    label: "After Dinner (8:30-10:30PM)",
  },
  { value: "Late Night (10:30PM-12)", label: "Late Night (10:30PM-12)" },
  { value: "Afters (12-2AM)", label: "Afters (12-2AM) " },
];

const isSelected = (item, value) => {
  const selected = value.includes(item);
  return selected;
};

const getValue = (index, date, value) => {
  const val = value[0];
  //convert date to hh:mmA
  const formattedDate = moment(date).format("hh:mmA");
  // concat value with zero index
  // 11:00AM-11:30AM

  const values = value[0] ? value[0].split("-") : ["", ""];
  values[index] = formattedDate;
  return [values.join("-")];
};

export function TimeOfDay({
  onChange,
  value = [],
}: {
  onChange: any;
  value: string[];
}) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const isSelectedFromList = weekDays.find((item) =>
      value.includes(item.value)
    );
    if (value?.length > 0 && !isSelectedFromList) {
      setChecked(true);
    }
  }, [value]);
  const customValue = value?.[0]?.split("-") || [];
  return (
    <div>
      <div className="text-sm font-semibold text-black mb-2">Time of Day</div>
      <div className="mt-[15px] flex flex-wrap gap-2 items-center">
        {weekDays.map((day) => (
          <SelectableChip
            label={day.label}
            selected={isSelected(day.value, value)}
            onClick={() => {
              let newValue: string[] = [];
              if (isSelected(day.value, value)) {
                newValue = value.filter((e) => e !== day.value);
              } else {
                newValue = [...value, day.value];
              }
              onChange("time_of_day", newValue);
              setChecked(false);
            }}
          />
        ))}
      </div>
      <div className="mt-3 flex items-center">
        <div className="flex items-center mr-3 gap-2">
          <input
            type="checkbox"
            id="place"
            name="place"
            className="w-5 h-5 border border-[#8D8E92] accent-black rounded-md"
            checked={checked}
            onChange={() => {
              onChange("time_of_day", []);
              if (!checked) {
                setChecked(true);
              } else {
                setChecked(false);
              }
            }}
          />
          <span className="text-[#1D1E1B] text-sm">Custom time</span>
        </div>
        <div className="flex items-center gap-1.5">
          <TimePicker
            value={
              checked
                ? customValue[0]
                  ? new Date(moment(customValue[0], "hh:mmA").toISOString())
                  : ""
                : ""
            }
            onChange={(date) =>
              onChange("time_of_day", getValue(0, date, value))
            }
            showIcon={false}
            disabled={!checked}
            placeholder="HH:MM"
            className="!py-2 !px-2.5 !rounded border !border-[#ddd]  font-medium text-sm"
          />
          <span className="text-[#1D1E1B]/30 text-sm">to</span>
          <TimePicker
            value={
              checked
                ? customValue[1]
                  ? new Date(moment(customValue[1], "hh:mmA").toISOString())
                  : ""
                : ""
            }
            onChange={(date) =>
              onChange("time_of_day", getValue(1, date, value))
            }
            placeholder="HH:MM"
            minDate={new Date(moment(customValue[0], "hh:mmA").toISOString())}
            showIcon={false}
            disabled={!checked}
            className="!py-2 !px-2.5 !rounded border !border-[#ddd]  font-medium text-sm"
          />
        </div>
      </div>
    </div>
  );
}
