import UIPopover from "../../components/Popover/Popover";
import { Button } from "../../components/Button";
import { useEffect, useRef, useState } from "react";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as More } from "../../assets/icons/More.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/Search.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as Crown } from "../../../assets/icons/crown.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/Chevrons.svg";
import { ReactComponent as Eye } from "../../assets/icons/eye.svg";
import { ReactComponent as BanIcon } from "../../assets/icons/slash.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { ShowToast } from "../../components/Toast";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/AnimatedContainer";
import { EmptyTableView } from "../../components/EmptyTableView";
import { CrownTooltip } from "../users/components/CrownTooltip";
import { UserIdWithTooltip } from "../users/components/UserIdWithTooltip";
import { PlaceView } from "../places/View/View";
import { UserDeleteConfirm } from "../users/components/UserDeleteConfirm";
import { BlockedUserList } from "../users/List/BlockedUser";
import { ReportedUserList } from "../users/List/ReportedUsers";
import useUsers from "../users/List/useUsers";
import { UserBlockConfirm } from "../users/components/UserBlockConfirm";
import usePlaces from "./usePlaces";
import { PlaceDeleteConfirm } from "./View/component/PlaceDeleteConfirm";
import formatType from "../../helpers/formatType";
import { AddPlaceForm } from "./PlacesForm/Form";
import { AddSearchPlace } from "./PlacesForm/AddSearchPlace";
import { EditPlaceForm } from "./View/component/EditPlaceForm";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b"
        >
          <td className="pl-5 name">
            <div className="flex items-center gap-3">
              <div className="w-10 h-10 transition bg-gray-200 rounded-full animate-pulse duration-50" />
              <div className="flex flex-col gap-1">
                <div className="flex items-center gap-1">
                  <div className="w-24 h-4 transition bg-gray-200 animate-pulse duration-50" />
                  <div className="w-4 h-4 transition bg-gray-200 animate-pulse duration-50" />
                </div>
                <div className="w-16 h-4 transition bg-gray-200 animate-pulse duration-50" />
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="h-4 transition bg-gray-200 w-36 animate-pulse duration-50" />
          </td>
          <td className="account-manager">
            <div className="w-6 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td>
            <div className="w-8 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
          <td>
            <div className="w-6 h-4 transition bg-gray-200 animate-pulse duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

export function PlacesList() {
  const {
    updateFilters,
    filters,
    isLoading,
    isFetching,
    refetch,
    places,
    total,
    loadMore,
  } = usePlaces({ type: "normal" });
  const [removePlaceModal, setRemovePlaceModal] = useState<any>(null);
  const [place, setPlace] = useState<any>(null);
  const [deleting, setDeleting] = useState(false);
  const [showAddPlaceModal, setShowAddPlaceModal] = useState(false);

  const handleSort = (key) => {
    if (key === filters.sort_by) {
      updateFilters({
        sort_by: filters.sort_order === "DESC" ? "" : key,
        sort_order:
          filters.sort_order === "ASC"
            ? "DESC"
            : filters.sort_order === "DESC"
              ? ""
              : "ASC",
      });
    } else {
      updateFilters({ sort_order: "ASC", sort_by: key });
    }
  };

  const handleRemovePlaceConfirm = async () => {
    try {
      // api call
      const place = removePlaceModal;
      setDeleting(true);
      const response = await api.deletePlace({ id: place.id });
      setDeleting(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(place.id, true);
          setRemovePlaceModal(null);
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          places &&
          total &&
          places.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, places, total, isLoading]);

  // useEffect(() => {
  //   if (user) {
  //     const newUser = places?.find(
  //       (category) => category.user_id === user.user_id
  //     );
  //     if (newUser) {
  //       setUser(newUser);
  //     }
  //   }
  // }, [places, user]);

  const clearSearchText = () => {
    updateFilters({ keyword: "" });
  };

  const handleMaintenance = () => {
    ShowToast({
      type: "error",
      message: "Add Places is Under Maintenance",
    });
  };

  const onRefetch = () => {
    setPlace(null);
    refetch("", false, true);
  };

  return (
    <div className="p-7 pt-6 bg-background h-[100vh]">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-black text-[32px] font-bold">Places</h1>
        {/* <Button
          onClick={() => setShowAddPlaceModal(true)}
          // onClick={() => handleMaintenance()}
          className="!rounded-full"
        >
          + Add a place
        </Button> */}
      </div>
      <LoadAnimatedContainer>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] px-3 py-5 h-[calc(100vh-115px)]">
          <div className="flex flex-wrap items-center justify-between gap-6 pl-2">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  value={filters?.keyword}
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
                <CloseIcon
                  className={`[&_path]:stroke-black [&_path]:stroke-[1px] cursor-pointer ${!filters?.keyword && "opacity-0"
                    }`}
                  onClick={clearSearchText}
                />
              </div>
            </div>
            {/* <div className="flex items-center gap-3">
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setReportedUserModal(true)}
              >
                Reported users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
              <div className="h-[13px] w-[1px] bg-[#ddd]" />
              <Button
                variant="link"
                scaleAnimated={false}
                className="!p-0 mr-5 !gap-0 items-center text-base rounded-none hover:!text-blackButtonHover group text-black"
                onClick={() => setBlockedUserModal(true)}
              >
                Blocked users{" "}
                <ChevronRight className="transition duration-75 group-hover:translate-x-[2px]" />
              </Button>
            </div> */}
          </div>
          <div className="mt-5 text-[14px] max-h-[calc(100vh-210px)] overflow-auto">
            <table className="w-full">
              <colgroup>
                <col style={{ width: "30%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td
                    onClick={() => handleSort("name")}
                    className="pl-5 rounded-l"
                  >
                    <div className="flex items-center cursor-pointer">
                      Place Name
                      {filters.sort_by === "name" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Address
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Activies
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Locality
                      {filters.sort_by === "locality" ? (
                        <ArrowRight
                          stroke="#2A2B2F"
                          className={`sort-icon ${filters.sort_order === "ASC"
                              ? "rotate-[180deg]"
                              : "rotate-[0deg]"
                            }`}
                        />
                      ) : null}
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {!isLoading && !isFetching && !places?.length && (
                  <EmptyTableView
                    cols={7}
                    message="No places found"
                    className="h-[calc(100vh-225px)]"
                  />
                )}
                {places?.map((aclient, i) => (
                  <tr
                    key={aclient.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setPlace(aclient)}
                  >
                    <td className="pl-5 name">
                      <div className="flex items-center gap-3">
                        <img
                          src={
                            aclient?.images?.length > 0
                              ? aclient?.images[0]
                              : "/images/places-template.png"
                          }
                          onError={(e) => {
                            e.currentTarget.src = "/images/places-template.png";
                          }}
                          className="object-cover w-10 h-10 rounded-full"
                          alt="user"
                        />
                        <div className="flex flex-col">
                          <div className="flex items-center gap-1">
                            <h5>{aclient.display_name}</h5>
                          </div>
                          <UserIdWithTooltip
                            userId={formatType(aclient.type || "")}
                          />
                          {/* <UserIdWithTooltip userId={aclient.type} /> */}
                        </div>
                      </div>
                    </td>
                    <td className="subscription">
                      <UserIdWithTooltip
                        userId={formatType(aclient.formatted_address || "")}
                        className="!max-w-[225px] !text-black"
                      />
                    </td>
                    {/* <td className="account-manager">
                      {moment
                        .utc(aclient.date_created)
                        .local()
                        .format("MMM DD,YYYY")}
                    </td> */}
                    <td className="account-executive">
                      <div className="flex flex-col gap-1">
                        {aclient.activities?.map((activity) => (
                          <span className="w-fit block py-[3px] px-2 bg-[#F5F5F5] border border-[rgba(0,0,0,0.07)] rounded text-xs text-black">
                            {activity}
                          </span>
                        ))}
                      </div>
                    </td>
                    <td className="mrr">
                      <span>{aclient.locality}</span>
                    </td>
                    {/* <td>
                      {aclient?.last_activity_date
                        ? moment
                            .utc(aclient.last_activity_date)
                            .local()
                            .format("MMM DD,YYYY | hh:mm A")
                        : "-"}
                    </td> */}
                    <td onClick={stopPropagate}>
                      <UIPopover
                        trigger={
                          <span className="block p-2 border rounded-lg border-gray-270">
                            <More />
                          </span>
                        }
                      >
                        {(close) => (
                          <Dropdown
                            removeUser={() => {
                              close();
                              setRemovePlaceModal(aclient);
                            }}
                            onView={() => {
                              close();
                              setPlace(aclient);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {(isLoading || isFetching) && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
      </LoadAnimatedContainer>
      {/* <PlaceView
        open={!!place}
        onClose={() => setPlace(null)}
        place={place}
        refetch={refetch}
      /> */}
      <PlaceDeleteConfirm
        title={`Are you sure you want to remove ‘${removePlaceModal?.display_name}’?`}
        open={!!removePlaceModal}
        onClose={() => setRemovePlaceModal(null)}
        onConfirm={handleRemovePlaceConfirm}
        loading={deleting}
      />
      {/* {showAddPlaceModal && (
        <AddPlaceForm
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )} */}
      {showAddPlaceModal && (
        <AddSearchPlace
          open={showAddPlaceModal}
          onClose={(refresh?: boolean) => {
            if (refresh) {
              refetch();
            }
            setShowAddPlaceModal(false);
          }}
        />
      )}
      <EditPlaceForm
        open={!!place}
        onClose={() => setPlace(null)}
        place={place}
        refetch={onRefetch}
      />
    </div>
  );
}

const Dropdown = ({ removeUser, onView }) => {
  return (
    <div className="z-10 w-40 bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-[#F2F2F2] w-full text-left !justify-start"
            onClick={onView}
            scaleAnimated={false}
          >
            <Eye />
            View Place
          </Button>
        </li>
        <li>
          <Button
            variant="icon"
            className="block py-2 !px-4 hover:bg-gray-100 text-[#F54343] w-full text-left !justify-start"
            onClick={removeUser}
            scaleAnimated={false}
          >
            <TrashIcon />
            Remove place
          </Button>
        </li>
      </ul>
    </div>
  );
};
