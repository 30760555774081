//<div style="max-width:100%;overflow:hidden;color:red;width:500px;height:500px;">
//    <div id="embed-map-display" style="height:100%; width:100%;max-width:100%;">
//          <iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Salt+&+Straw+West+Hollywood+818+Beverly+Blvd,+Beverly+Hills,+CA&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
//     </div>
//     <style>#embed-map-display .text-marker{}.map-generator{max-width: 100%; max-height: 100%; background: none;</style>
//</div>
//
import { TextInput } from "../../../components/TextInput";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button } from "../../../components/Button";
import { UploadPicture } from "../../../components/Uploads/UploadPicture";
import { api } from "../../../services/api";
import { fileUpload } from "../../../services/file-upload";
import { Loading } from "../../../components/Loading/Loading";
import { ShowToast } from "../../../components/Toast";
import { TextArea } from "../../../components/TextArea";
import { FullModal } from "../../../components/Modals/FullModal";
import { priceMapper } from "./../../../helpers/priceMapper";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { SelectInput } from "../../../components/SelectInput";
import { ActivityProfilePicture, ImageView } from "./ActivityProfilePicture";
import { PlacesList } from "./PlacesList";
import { RecommendationFrequency } from "./RecommendationFrequency";
import { DaysOfWeek } from "./DaysOfWeek";
import { GroupSize } from "./GroupSize";
import { TimeOfDay } from "./TimeOfDay";
import { EditPlaceForm } from "../../places/View/component/EditPlaceForm";
import { useAutoSave } from "../../places/View/component/useAutoSave";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { CategorySelect } from "./CategorySelect";
import { ExperienceSaveAlert } from "./ExperienceSaveAlert";
import { ImageCrop } from "../../../components/ImageCropper/ImageCrop";
import { ImageViewModal } from "./ImageViewModal";

export const anyTrue = (object) => {
  if (!object) return false;
  return Object.values(object).filter((e) => e).length > 0;
};

export const statusList = [
  { label: "Draft", value: 0 },
  { label: "Live", value: 3 },
  { label: "Boosted", value: 1 },
];

export const getImage = (image: string | undefined, file: File | null) => {
  if (file) {
    return URL.createObjectURL(file);
  }
  if (image) {
    return image;
  }
};

export const ItemTypes = {
  CARD: "card",
};

export function ActivityForm({
  open,
  onClose,
  activity,
  refetch,
  categories,
}: {
  open: boolean;
  onClose: (refetch?: boolean) => void;
  activity?: any;
  refetch: (
    refetchId?: string,
    isDelete?: boolean,
    refetchAll?: boolean
  ) => void;
  categories: any[];
}) {
  const [showAlert, setShowAlert] = useState(false);
  const [placeForm, setPlaceForm] = useState<any>(null);
  const [saving, setSaving] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [originalActivity, setOriginalActivity] = useState<any>({
    days_of_week: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    override_place_images: false,
    override_place_desc: false,
  });
  const [activityForm, setActivityForm] = useState<any>({
    days_of_week: {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    override_place_images: true,
    override_place_desc: false,
    status: 0,
  });
  const [newFiles, setNewFiles] = useState<any[]>([]);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);
  const [removeImageIndex, setRemoveImageIndex] = useState("");
  const [showCropModal, setShowCropModal] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<any>(null);
  const [showImageViewModal, setShowImageViewModal] = useState<boolean>(false);

  const autoSaveData = useCallback((data) => {
    // console.log("Auto save", data);
    handleSubmit(data, true);
  }, []);

  // A hook which triggers the save function every 2 seconds
  // This is useful for text fields where user can type and we can save the data at regular intervals
  const { updatePreviousData } = useAutoSave(
    autoSaveData,
    open ? activityForm : null
  );

  // When we have some selection or uploads data
  // that we can save directly without waiting for auto save
  /**
   * Fields that can be saved directly
   * 1. activity_images
   * 2. places
   */
  const handleAutoSave = (updatedData: any) => {
    const data = { ...activityForm, ...updatedData };
    updatePreviousData(data);
    autoSaveData(data);
  };

  const handleNewFile = (file) => {
    setFile(file);
  };

  const handleRemoveImage = (url) => {
    setRemoveImageIndex(url);
  };

  const handleChange = (e: any) => {
    setActivityForm({
      ...activityForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleCroppedImage = async (file: File) => {
    // upload the file
    const url = activityForm?.card_cropped_image;
    const type = url?.split(".")?.pop();
    const fileName = url?.split("/")?.pop();
    const contentType = `image/${type}`;
    const imageResponse = await fileUpload(
      file,
      "activities",
      fileName,
      contentType
    );
    if (imageResponse.response.status === 200 && imageResponse?.url) {
      setActivityForm((prev) => ({
        ...prev,
        card_cropped_image: imageResponse?.url,
      }));
    }
  };

  const handleSubmit = async (activityForm: any, autoSave = false) => {
    if (!activityForm.experience_name) {
      return;
    }
    const ids = activityForm.category.map((category) => category.value);
    const category = categories
      .filter((category) => ids.includes(category.id))
      ?.map((category) => category.category_name);
    const liveId = statusList.find((item) => item.label === "Live")?.value;
    if (Number(activityForm.status || 0) === liveId && category.length === 0) {
      if (!autoSave) {
        setShowAlert(true);
      }
      return;
    }
    let payload: any = {
      // id: activityForm.id,
      category: category,
      experience_name: activityForm.experience_name,
      experience_custom_name: activityForm.experience_custom_name || "",
      status: Number(activityForm.status || 0),
      activity_images: activityForm.activity_images || [],
      override_place_images: activityForm.override_place_images ? 1 : 0,
      override_place_desc: activityForm.override_place_desc ? 1 : 0,
      days_of_week: {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        ...activityForm?.days_of_week,
      },
      activity_desc: activityForm?.activity_desc || "",
      card_cropped_image: activityForm?.card_cropped_image || "",
      activity_short_desc: activityForm?.activity_short_desc || "",
      admin_notes: activityForm?.admin_notes || "",
      happening_now: false,
      limited_time: activityForm?.limited_time,
      time_of_day: activityForm?.time_of_day,
      places:
        activityForm?.places?.map((place) => ({
          ...place,
          place_id: place.id || place.place_id,
          name: place?.displayName?.text || place?.name,
          address: place?.formattedAddress || place?.address,
          rating: place?.rating,
          price_level: place?.priceLevel || place?.price_level,
          primary_type:
            place?.primaryTypeDisplayName?.text || place?.primary_type,
        })) || [],
      group_size: activityForm?.group_size || {},
      recommendation_frequency: activityForm?.recommendation_frequency,
    };
    if (activityForm.id) {
      payload.id = `${activityForm.id}`;
    }
    try {
      !autoSave && setSaving(true);
      // if (file) {
      //   const imageResponse = await fileUpload(file, "activities");
      //   if (imageResponse.response.status === 200 && imageResponse?.url) {
      //     payload.activity_image = imageResponse?.url;
      //   }
      //   console.log(imageResponse);
      // }
      const API = activityForm.id ? api.updateActivity : api.createActivity;
      const response = await API(payload, autoSave);
      setSaving(false);
      if ([200, 201].includes(response.status) && response?.data?.status) {
        refetch("", false, true);
        if (!activityForm.id && response?.data?.activity_id) {
          // set id
          setActivityForm((prev) => ({
            ...prev,
            id: response?.data?.activity_id,
          }));
          updatePreviousData({
            ...activityForm,
            id: response?.data?.activity_id,
          });
        }
        if (!autoSave) {
          onClose(true);
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
        }
      } else {
        if (!autoSave) {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      setSaving(false);
      ShowToast({
        type: "error",
        message: "Failed to save",
      });
    }
  };

  useEffect(() => {
    if (open) {
      const category = categories
        .filter((category) =>
          activity?.category?.includes(category.category_name)
        )
        ?.map((category) => ({
          value: category.id,
          label: category.category_name,
        }));
      setActivityForm({ ...activity, category });
      updatePreviousData({ ...activity, category });
      setOriginalActivity({ ...activity, category });
    }
  }, [open, activity]);

  const isObjectChanged = useMemo(() => {
    return JSON.stringify(activityForm) !== JSON.stringify(originalActivity);
  }, [activityForm, originalActivity]);

  const isEdit = !!activity?.id;

  if (!open) return null;

  const isValidate = (form = activityForm) => {
    // console.log({ form });
    // return (
    //   form.category &&
    //   form.experience_name &&
    //   form.recommendation_frequency &&
    //   form?.time_of_day?.length > 0 &&
    //   form?.status !== undefined &&
    //   form?.places?.length > 0 &&
    //   (form?.group_size?.min
    //     ? form?.group_size?.max &&
    //       Number(form?.group_size?.max) > Number(form?.group_size?.min)
    //     : true) &&
    //   anyTrue(form?.days_of_week)
    // );

    // temporarily remove validation
    return true;
  };

  const handleOnClose = () => {
    setNewFiles([]);
    setFilesToDelete([]);
    onClose();
  };

  const newImageUrl = file
    ? URL.createObjectURL(file)
    : activityForm.activity_image;

  const getPlacesImages = async (places: any) => {
    const imageList: string[] = [];
    for (let i = 0; i < places?.length; i++) {
      const placeId = places[i]?.id;
      const response = await api.getPlaceDetails(placeId);
      if (response?.data) {
        const placeDetails = response?.data;
        const images = placeDetails?.images || [];
        imageList.push(...images);
      }
    }
    return imageList;
  };

  const populatePlaceDetails = async (places, prevPlaces) => {
    // use Set to remove duplicate images

    //
    let data: any = {};
    const firstPlaceId = places[0]?.id;
    if (firstPlaceId && firstPlaceId !== prevPlaces?.[0]?.id) {
      const placeResponse = await api.getPlaceDetails(firstPlaceId);
      if (placeResponse?.data) {
        const placeDetails = placeResponse?.data;
        data = {
          activity_images: placeDetails?.images || [],
          card_cropped_image: placeDetails?.images?.[0] || "",
          days_of_week: placeDetails?.days_of_week || [],
          time_of_day: placeDetails?.time_of_day || [],
          activity_desc: placeDetails?.summary || "",
          admin_notes: placeDetails?.admin_notes || "",
        };
      }
    }
    // check for removed places
    const removedPlaces =
      prevPlaces?.filter(
        (p) => places?.findIndex((place) => place.id === p.id) === -1
      ) || [];
    const imagesToRemove = await getPlacesImages(removedPlaces);
    const newPlaces = places?.filter(
      (p) =>
        prevPlaces.findIndex((place) => place.id === p.id) === -1 &&
        firstPlaceId !== p.id
    );
    const imagesToAdd = await getPlacesImages(newPlaces);
    console.log("images to remove", imagesToRemove);
    console.log("images to add", imagesToAdd);
    setActivityForm((prev) => {
      console.log("activity images", prev.activity_images);
      const imageSet = new Set<string>([]);
      const remainingImages = (prev.activity_images || []).filter(
        (image) => !imagesToRemove.includes(image)
      );
      (data.activity_images || []).forEach((image) => imageSet.add(image));
      remainingImages.forEach((image) => imageSet.add(image));
      imagesToAdd.forEach((image) => imageSet.add(image));
      const images = Array.from(imageSet);
      return {
        ...prev,
        ...data,
        places,
        activity_images: [...images],
        card_cropped_image: images?.[0] || "",
      };
    });
  };

  console.log("cropped image", croppedImage);

  return (
    <DndProvider backend={HTML5Backend}>
      <FullModal
        contentClassName="!px-0 !bg-white"
        open={open}
        onClose={handleOnClose}
      >
        <div className="flex flex-col">
          <div className="border-b border-b-[#ddd] pb-4 mb-2">
            <div className="flex w-[900px] mx-auto items-center justify-between">
              <div>
                <span className="text-2xl font-bold tracking-tight">
                  {!activityForm?.id
                    ? "Add an experience"
                    : "Edit an experience"}
                </span>
              </div>
              <Button
                variant="primary"
                className="!rounded-full !py-2.5 !font-medium !text-sm text-white"
                onClick={() => handleSubmit(activityForm)}
                disabled={saving || !isValidate() || !isObjectChanged}
              >
                <CheckIcon />
                Save
                {saving && <Loading />}
              </Button>
            </div>
          </div>
          <div className="h-[calc(100vh-110px)] overflow-auto">
            <div className="px-5 w-[900px] mx-auto flex flex-col justify-between items-start py-6">
              <div className="flex items-center justify-start flex-1 w-full gap-4">
                {/* <input
                  type="text"
                  placeholder="Activity name"
                  className="text-black flex-1 border-b pb-4 !outline-none border-black/20 font-medium text-xl flex-col justify-start items-start gap-5 flex"
                  value={activityForm.activity}
                  onChange={(e) =>
                    setActivityForm((form) => ({
                      ...form,
                      activity: e.target.value,
                    }))
                  }
                /> */}
                {activityForm?.card_cropped_image && (
                  <ImageView
                    image={activityForm?.card_cropped_image}
                    onEdit={() => {
                      setCroppedImage(activityForm?.card_cropped_image);
                      setShowCropModal(true);
                    }}
                    onClick={() => {
                      setShowImageViewModal(true);
                    }}
                    className="!border-solid"
                  />
                )}
                <input
                  type="text"
                  placeholder="Experience name"
                  className="text-black flex-1 border-b pb-4 !outline-none border-black/20 font-medium text-xl flex-col justify-start items-start gap-5 flex"
                  value={activityForm.experience_name}
                  onChange={(e) =>
                    setActivityForm((form) => ({
                      ...form,
                      experience_name: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  placeholder="Custom name"
                  className="text-black flex-1 border-b pb-4 !outline-none border-black/20 font-medium text-xl flex-col justify-start items-start gap-5 flex"
                  value={activityForm.experience_custom_name}
                  onChange={(e) =>
                    setActivityForm((form) => ({
                      ...form,
                      experience_custom_name: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="flex flex-col gap-3 my-7">
                <div className="text-sm font-semibold text-black leading-[17.92px]">
                  Experience Images
                </div>
                <div className="flex items-center gap-2">
                  <ActivityProfilePicture
                    onChange={(images) => {
                      handleAutoSave({ activity_images: images });

                      setActivityForm((prev) => ({
                        ...prev,
                        activity_images: images,
                        card_cropped_image:
                          prev?.card_cropped_image || images?.[0],
                      }));
                    }}
                    // onRemove={() => {
                    //   setActivityForm((prev) => ({
                    //     ...prev,
                    //     activity_image: "",
                    //   }));
                    //   setFile(null);
                    // }}
                    images={activityForm.activity_images || []}
                    onSelect={(image) => {
                      setCroppedImage(image);
                      setActivityForm((prev) => ({
                        ...prev,
                        card_cropped_image: image,
                      }));
                    }}
                  />
                </div>
                {/* <div className="flex items-center gap-2">
                  <input
                    type="checkbox"
                    id="override_place_images"
                    name="override_place_images"
                    className="w-5 h-5 border border-[#8D8E92] accent-black rounded-md"
                    checked={activityForm?.override_place_images}
                    onChange={() => {
                      setActivityForm((prev) => ({
                        ...prev,
                        override_place_images: !prev?.override_place_images,
                      }));
                    }}
                  />
                  <span className="text-[#1D1E1B] text-sm">
                    Override place photos
                  </span>
                </div> */}
              </div>
              <div className="flex-1 w-full mb-8 ">
                <CategorySelect
                  onChange={(e) => {
                    // console.log(e);
                    setActivityForm((prev) => ({
                      ...prev,
                      category: e,
                    }));
                  }}
                  value={activityForm.category || []}
                />
              </div>
              <div className="w-full flex-1 flex items-start gap-[30px]">
                <div className="flex flex-col flex-1 gap-8">
                  <div className="flex-1 flex gap-3.5 items-center">
                    <div className="flex-1">
                      <label className="mb-2 text-sm font-semibold text-black">
                        Status
                      </label>
                      <SelectInput
                        className="!py-2.5"
                        data={statusList}
                        value={activityForm.status}
                        onChange={(e) => {
                          {
                            // console.log("status change ", e.target.value);
                            setActivityForm((prev) => ({
                              ...prev,
                              status: e.target.value,
                            }));
                          }
                        }}
                      />
                    </div>
                  </div>
                  <DaysOfWeek
                    onChange={(field, value) => {
                      setActivityForm((prev) => ({
                        ...prev,
                        [field]: value,
                      }));
                    }}
                    value={activityForm.days_of_week}
                    limitedTime={activityForm.limited_time || {}}
                  />
                  <TimeOfDay
                    onChange={(field, val) =>
                      setActivityForm((prev) => ({
                        ...prev,
                        [field]: val,
                      }))
                    }
                    value={activityForm.time_of_day || []}
                  />
                  <RecommendationFrequency
                    onChange={(value) =>
                      setActivityForm((prev) => ({
                        ...prev,
                        recommendation_frequency: value,
                      }))
                    }
                    value={activityForm.recommendation_frequency}
                  />
                  {/* <GroupSize
                    onChange={(field, value) => {
                      setActivityForm((prev) => ({
                        ...prev,
                        group_size: value,
                      }));
                    }}
                    value={activityForm.group_size || {}}
                  /> */}

                  <div>
                    <div className="mb-2 text-sm font-semibold text-black">
                      Short Description
                    </div>
                    <TextArea
                      value={activityForm.activity_short_desc || ""}
                      onChange={(e) =>
                        setActivityForm((prev) => ({
                          ...prev,
                          activity_short_desc: e.target.value,
                        }))
                      }
                      maxLength={75}
                      placeholder="Write here"
                    />
                  </div>
                  <div>
                    <div className="mb-2 text-sm font-semibold text-black">
                      Long Description
                    </div>
                    <TextArea
                      value={activityForm.activity_desc || ""}
                      onChange={(e) =>
                        setActivityForm((prev) => ({
                          ...prev,
                          activity_desc: e.target.value,
                        }))
                      }
                      maxLength={1500}
                      placeholder="Write here"
                    />
                    {/* <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        id="override_place_desc"
                        name="override_place_desc"
                        className="w-5 h-5 border border-[#8D8E92] accent-black rounded-md"
                        checked={activityForm?.override_place_desc}
                        onChange={() => {
                          setActivityForm((prev) => ({
                            ...prev,
                            override_place_desc: !prev?.override_place_desc,
                          }));
                        }}
                      />
                      <span className="text-[#1D1E1B] text-sm">
                        Override place description
                      </span>
                    </div> */}
                  </div>
                  <div>
                    <div className="mb-2 text-sm font-semibold text-black">
                      Admin Notes
                    </div>
                    <TextArea
                      value={activityForm.admin_notes || ""}
                      onChange={(e) =>
                        setActivityForm((prev) => ({
                          ...prev,
                          admin_notes: e.target.value,
                        }))
                      }
                      maxLength={1500}
                      placeholder="Write here"
                    />
                  </div>
                </div>
                <div className="self-stretch flex-1 h-auto">
                  <PlacesList
                    places={activityForm.places || []}
                    onChange={async (places) => {
                      const prevPlaces = activityForm.places;
                      populatePlaceDetails(places || [], prevPlaces || []);

                      // if prevPlaces 0 element id is not same then populatePlaceDetails
                      // populate images from rest of the places
                    }}
                    onEdit={(place) => setPlaceForm(place)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showCropModal && (
          <ImageCrop
            onClose={() => setShowCropModal(false)}
            isOpen={showCropModal}
            onImageChange={({ url, file }) => {
              console.log("image", url);
              setCroppedImage(url);
              handleCroppedImage(file);
            }}
            image={activityForm?.card_cropped_image}
          />
        )}

        <EditPlaceForm
          open={!!placeForm}
          onClose={() => setPlaceForm(null)}
          place={placeForm}
          refetch={() => {}}
        />
        <ExperienceSaveAlert
          title="Validation Error"
          open={showAlert}
          onClose={() => setShowAlert(false)}
        />

        <ImageViewModal
          open={showImageViewModal !== false ? true : false}
          onClose={() => setShowImageViewModal(false)}
          images={[activityForm.card_cropped_image]}
          index={0}
        />
      </FullModal>
    </DndProvider>
  );
}
