import React, { useEffect, useState } from "react";
import { FullModal } from "../../../components/Modals/FullModal";
import { Button } from "../../../components/Button";
import { ReactComponent as BanIcon } from "../../../assets/icons/slash.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit-2.svg";
import { UserInfoCard } from "./component/UserInfoCard";
import { api } from "../../../services/api";
import { SpontaneousTable } from "./component/SpontaneousTable";
import moment from "moment";
import { EditUserForm } from "./component/EditUserForm";
import { UserDeleteConfirm } from "../components/UserDeleteConfirm";
import { UserBlockConfirm } from "../components/UserBlockConfirm";
import { ShowToast } from "../../../components/Toast";

export function UserView({
  open,
  onClose,
  user,
  refetch,
}: {
  open: boolean;
  onClose: () => void;
  user: any;
  refetch: (refetchId?: string, isDelete?: boolean) => void;
}) {
  // console.log("user", user);
  const [matches, setMatches] = React.useState<any>([]);
  const [revealCounts, setRevealCounts] = React.useState<any>({});
  const [receivedReveals, setReceivedReveals] = React.useState<any>([]);
  const [revealLoading, setRevealLoading] = React.useState(false);
  const [selectedTab, setSelectedTab] = React.useState<"sent" | "received">(
    "sent"
  );
  const [deleting, setDeleting] = useState(false);
  const [blocking, setBlocking] = useState(false);
  const [deactivateUser, setDeactivateUser] = useState<any>(null);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [userInfo, setUserInfo] = React.useState<any>({});
  const [userForm, setUserForm] = React.useState<any>(null);
  const handleEdit = () => {
    setUserForm({
      ...userInfo,
    });
  };

  const handleBlock = () => {
    console.log("block");
    setDeactivateUser(userInfo);
  };

  const handleUnBlock = async (user_id: string) => {
    try {
      const response = await api.blockUser({ user_id: user_id });
      if (response.status === 200) {
        if (response?.data?.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          onClose();
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const fetchUserInfo = async (userId: string) => {
    const response = await api.getUserInfo(userId);
    console.log(response);
    setUserInfo(response?.data || {});
  };

  const fetchUserMatches = async (userId: string) => {
    setRevealLoading(true);
    const response = await api.getUserMatches(userId);
    console.log(response);
    setRevealLoading(false);
    // setRevealCounts({
    //   receivedCount: response?.data?.receivedCount,
    //   sentCount: response?.data?.sentCount,
    // });
    setMatches(response?.data || []);
  };

  // const fetchReceivedReveals = async (userId: string) => {
  //   setRevealLoading(true);
  //   const response = await api.getReveals({ id: userId, type: "received" });
  //   console.log(response);
  //   setRevealLoading(false);
  //   setRevealCounts({
  //     receivedCount: response?.data?.receivedCount,
  //     sentCount: response?.data?.sentCount,
  //   });
  //   setReceivedReveals(response?.data?.data || []);
  // };

  useEffect(() => {
    if (user && open) {
      fetchUserInfo(user.user_id);
      fetchUserMatches(user.user_id);
      // if (selectedTab === "sent") {
      //   fetchSentReveals(user.user_id);
      // } else {
      //   fetchReceivedReveals(user.user_id);
      // }
    }
  }, [user, open]);

  const handleDeleteUser = () => {
    setRemoveUserModal(userInfo);
  };

  const handleDeactivateUserConfirm = async () => {
    try {
      // api call
      const user = deactivateUser;
      setBlocking(true);
      const response = await api.blockUser({ user_id: user.user_id });
      setBlocking(false);
      if (response.status === 200) {
        if (response?.data?.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          setDeactivateUser(null);
          onClose();
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  const handleRemoveUserConfirm = async () => {
    try {
      // api call
      const user = removeUserModal;
      setDeleting(true);
      const response = await api.deleteUser({ user_id: user.user_id });
      setDeleting(false);
      if (response.status === 200) {
        if (response.data.status) {
          ShowToast({
            type: "success",
            message: response?.data?.message,
          });
          refetch(user.user_id, true);
          setRemoveUserModal(null);
          onClose();
        } else {
          ShowToast({
            type: "error",
            message: response?.data?.message || "Something went wrong",
          });
        }
      }
    } catch (e) {
      ShowToast({
        type: "error",
        message: "Network request failed",
      });
    }
  };

  // const handleSelectedTab = (tab: "sent" | "received") => {
  //   setSelectedTab(tab);
  //   if (tab === "sent") {
  //     fetchSentReveals(user.user_id);
  //   } else {
  //     fetchReceivedReveals(user.user_id);
  //   }
  // };

  if (!open) return null;

  console.log(matches, receivedReveals);
  return (
    <FullModal open={open} onClose={onClose}>
      <div className="w-[900px] mx-auto flex flex-col">
        <div>
          <div className="flex justify-end mb-5 gap-6">
            <Button
              variant="icon"
              className="!p-0 text-black hover:text-blackButtonHover"
              onClick={handleEdit}
            >
              <EditIcon />
              Edit profile
            </Button>
            <Button
              variant="icon"
              className="!p-0 text-black hover:text-blackButtonHover"
              onClick={user?.is_blocked ? () => handleUnBlock(user?.user_id) : handleBlock}
            >
              <BanIcon />
              { user?.is_blocked ? 'Unblock user' : 'Block user' }
            </Button>
            <Button
              variant="icon"
              className="!p-0 text-black hover:text-[#F54343]"
              onClick={handleDeleteUser}
            >
              <TrashIcon />
              Remove User
            </Button>
          </div>
          <UserInfoCard user={userInfo} />
        </div>
        <div className="flex items-center gap-2 my-4">
          <span className="text-[#777777] text-sm">Last Activity:</span>
          <span className="text-[#202020] text-sm">
            {userInfo?.last_activity_date
              ? moment
                  .utc(userInfo.last_activity_date)
                  .local()
                  .format("MMM DD,YYYY | hh:mm A")
              : "-"}
          </span>
        </div>

        <div className="bg-white rounded-[16px] p-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-[#33363F] text-2xl font-bold">Spontaneous</h1>
            {/* <div className="bg-[#EEF1EF] rounded-full p-1 flex items-center gap-1">
              <button
                onClick={() => handleSelectedTab("sent")}
                className={`${
                  selectedTab === "sent"
                    ? "bg-white"
                    : "bg-transparent hover:bg-white/60"
                } rounded-full px-4 py-2 text-base text-[#33363F]`}
              >
                Reveals Sent ({revealCounts.sentCount})
              </button>
              <button
                onClick={() => handleSelectedTab("received")}
                className={`${
                  selectedTab === "received"
                    ? "bg-white"
                    : "bg-transparent hover:bg-white/60"
                } rounded-full px-4 py-2 text-base text-[#33363F]`}
              >
                Reveals Received ({revealCounts.receivedCount})
              </button>
            </div> */}
          </div>
          <div className="max-h-[calc(100vh-425px)] overflow-auto">
            <SpontaneousTable data={matches} loading={false} />
          </div>
        </div>
      </div>
      <EditUserForm
        open={!!userForm}
        onClose={() => setUserForm(null)}
        user={userForm}
        refetch={refetch}
      />

      <UserDeleteConfirm
        title={`Are you sure you want to remove ‘${removeUserModal?.full_name}’?`}
        open={!!removeUserModal}
        onClose={() => setRemoveUserModal(null)}
        onConfirm={handleRemoveUserConfirm}
        loading={deleting}
      />
      <UserBlockConfirm
        title={`Are you sure you want to block ‘${deactivateUser?.full_name}’?`}
        open={!!deactivateUser}
        onClose={() => setDeactivateUser(null)}
        onConfirm={handleDeactivateUserConfirm}
        loading={blocking}
      />
    </FullModal>
  );
}
