import moment from "moment";
import { ReactComponent as More } from "../../../../assets/icons/More.svg";
import { ReactComponent as Play } from "../../../../assets/icons/play.svg";
import { ReactComponent as InfoCircle } from "../../../../assets/icons/info-circle.svg";
import { ReactComponent as Subtract } from "../../../../assets/icons/subtract.svg";
import { AudioView } from "./Audio";
import { Loading } from "../../../../components/Loading/Loading";
import Tippy from "@tippyjs/react";
import { EmptyTableView } from "../../../../components/EmptyTableView";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr
          key={i}
          className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-4 border-b"
        >
          <td className="pl-5">
            <div className="w-48 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-36 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
          <td>
            <div className="w-24 h-4 bg-gray-200 animate-pulse transition duration-50" />
          </td>
        </tr>
      ))}
    </>
  );
}

function Contacts({ list }) {
  return (
    <div className="flex flex-col gap-2">
      {list?.map((item) => (
        <div key={item.id} className="flex items-center gap-2">
          <div className="w-4 h-4 rounded-full">
            <img
              src={item.user_image}
              className="w-full h-full object-cover rounded-full"
              alt=""
            />
          </div>
          <span className="text-sm text-[#202020]">{item.full_name}</span>
        </div>
      ))}
    </div>
  );
}

export function SpontaneousTable({ data, loading }) {
  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  return (
    <table className="w-full">
      <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
        <tr className="sticky top-0 z-[1]">
          <td className="rounded-l pl-5">
            Spontaneous on
          </td>
          <td>
            <div className="flex items-center">
              Place
            </div>
          </td>
          <td>
            <div className="flex items-center">
              Matched with
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        {loading && <LoadingSkeleton />}
        {data?.length === 0 && !loading && (
          <EmptyTableView
            cols={3}
            message="No records found"
            className="pt-10"
          />
        )}
        {data?.map((item) => (
          <tr
            key={item.user_match_id}
            className="[&_td]:py-4 border-b border-b-[#EFF1FF]"
          >
            <td>
              <div className="flex gap-1.5 text-[#202020] text-sm pl-5">
                <div className="max-w-[430px]">
                  {moment.utc(item.date_created).local().format("MMM DD,YYYY | hh:mm A")}
                </div>
              </div>
            </td>
            <td className="align-baseline w-[200px] text-[#202020] text-sm">
              {item.display_name}
            </td>
            <td className="align-baseline w-[150px]">
              {(
                item?.full_names
              ) ? (
                <div className="flex items-center gap-1">
                  <span className="text-sm text-[#202020]">
                    {item?.full_names}
                  </span>
                  {/* <Tippy
                    className="my-tooltip"
                    arrow={true}
                    interactive={true}
                    content={
                      <div className="flex p-4 bg-white rounded-[10px] shadow-[0px_4px_24px_0px_rgba(102,162,92,0.25)]">
                        <Contacts list={item.matched_list} />
                      </div>
                    }
                  >
                    <span className="cursor-pointer mt-[2px]">
                      <div className="w-4 h-4 rounded-full bg-[#03E7A3] flex justify-center items-center">
                        <Subtract className="[&_path]:fill-black" />
                      </div>
                    </span>
                  </Tippy> */}
                </div>
              ) : (
                "-"
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
