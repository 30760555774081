export function HighlightCard({
  icon,
  label,
  value,
  onClick,
  className = "",
  iconClassName = "",
  iconView,
}: {
  icon?: any;
  label: string | React.ReactNode;
  value: string;
  onClick?: any;
  className?: string;
  iconClassName?: string;
  iconView?: React.ReactNode;
}) {
  return (
    <div
      className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] flex ${className}`}
    >
      <div className="flex gap-5">
        <div className="flex items-center gap-3">
          {iconView ? (
            <>{iconView}</>
          ) : (
            <div
              className={`w-16 h-16 rounded-full relative flex items-center justify-center ${iconClassName}`}
            >
              {icon}
            </div>
          )}
        </div>
        <div className="flex flex-col justify-between ">
          <div className="text-black text-base font-medium">{label}</div>
          <div className="text-black text-[32px] font-bold">{value}</div>
        </div>
      </div>
    </div>
  );
}
